import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ServerResponse } from "src/app/models/server-response";
import { PagedResponse } from "src/app/models/pagedResponse";
import { environment } from "src/environments/environment";
import { endpoints } from "src/app/config/endpoint";
import { map } from "rxjs/operators";
import { Observable, of, forkJoin } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TicketService {
  constructor(private http: HttpClient) {}

  getAllTicketsCategerories(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.tickets}/TicketCategory`,
        { observe: "response", params }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  addnewTicket(payload): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/TicketCategory`,
      payload
    );
  }

  updateTicketDetail(id, payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/TicketCategory/Update`,
      payload
    );
  }

  deleteTickets(payload: Array<any>): Observable<any> {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/TicketCategory/Delete`,
      payload
    );
  }
  getAllTicketCategoryUnpaginated() {
    return this.http.get<any>(
      `${environment.API_URL}/${endpoints.getAllTicketCategoryUnpaginated}`
    );
  }

  splitTicket(payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/Split`,
      payload
    );
  }

  mergeTicket(payload) {
    const formData = new FormData();
    if (payload.MergeType === "1") {
      formData.append("TicketIds", JSON.stringify(payload.TicketIds));
      formData.append("Keywords", JSON.stringify(payload.Keywords));
      formData.append("TicketCategoryId", payload.TicketCategoryId);
      formData.append("TicketTypeId", payload.TicketTypeId);
      formData.append("CustomerId", payload.CustomerId);
      formData.append("Subject", payload.Subject);
      formData.append("Description", payload.Description);
      formData.append("AttachmentFile", payload.AttachmentFile);
      formData.append("ServiceLevelId", payload.ServiceLevelId);
      formData.append("ExistingCustomer", payload.ExistingCustomer);
      formData.append("TicketRouteTypeId", payload.TicketRouteTypeId);
      formData.append("TicketRoutePathId", payload.TicketRoutePathId);
      formData.append(
        "ExistingTicketHistoriesIds",
        JSON.stringify(payload.ExistingTicketHistoriesIds)
      );
      return this.http.post<ServerResponse>(
        `${environment.API_URL}/${endpoints.tickets}/Merge`,
        formData
      );
    } else {
      formData.append(
        "TicketIds",
        JSON.stringify(payload.ExistingTicketHistoriesIds)
      );
      formData.append("Keywords", JSON.stringify(payload.Keywords));
      formData.append("TicketCategoryId", payload.TicketCategoryId);
      formData.append("TicketTypeId", payload.TicketTypeId);
      formData.append("CustomerId", payload.CustomerId);
      formData.append("Subject", payload.Subject);
      formData.append("Description", payload.Description);
      formData.append("AttachmentFile", payload.AttachmentFile);
      formData.append("ServiceLevelId", payload.ServiceLevelId);
      formData.append("ExistingCustomer", payload.ExistingCustomer);
      formData.append("TicketRouteTypeId", payload.TicketRouteTypeId);
      formData.append("TicketRoutePathId", payload.TicketRoutePathId);
      formData.append(
        "ExistingTicketHistoriesIds",
        JSON.stringify(payload.ExistingTicketHistoriesIds)
      );
      formData.append("TicketAllowedUserRequest.TicketId", payload.FullName);
      formData.append(
        "TicketAllowedUserRequest.DepartmentId",
        payload.EmailAddress
      );
      formData.append(
        "TicketAllowedUserRequest.SubUnitId",
        payload.PhoneNumber
      );
      formData.append(
        "TicketAllowedUserRequest.SupportLevelId",
        payload.CustomerType
      );
      return this.http.post<ServerResponse>(
        `${environment.API_URL}/${endpoints.tickets}/Merge`,
        formData
      );
    }
  }

  createCustomerTicket(payload) {
    const formData = new FormData();
    formData.append("TicketCategoryId", payload.TicketCategoryId);
    formData.append("TicketTypeId", payload.TicketTypeId);
    formData.append(
      "ExistingTicketHistoriesIds",
      JSON.stringify(payload.ExistingTicketHistoriesIds)
    );
    formData.append("customerId", payload.CustomerId);
    formData.append("ServiceLevelId", payload.ServiceLevelId);
    formData.append("Subject", payload.Subject);
    formData.append("Description", payload.Description);
    formData.append("ExistingCustomer", payload.ExistingCustomer);
    formData.append("TicketRouteTypeId", payload.TicketRouteTypeId);
    formData.append("TicketRoutePathId", payload.TicketRoutePathId);
    formData.append("Keywords", JSON.stringify(payload.Keywords));
    if (payload.AttachmentFile) {
      formData.append("AttachmentFile", payload.AttachmentFile);
    }
    console.log(formData);
    return this.http.post(
      `${environment.API_URL}/${endpoints.tickets}`,
      formData
    );
  }

  getCustomerExistingTickets(customerId) {
    return this.http.get(
      `${environment.API_URL}/${endpoints.getCustomerExistingTickets}/${customerId}`
    );
  }

  getAllTickets(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(`${environment.API_URL}/${endpoints.tickets}`, {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }
  getOneTicket(id) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/${id}`
    );
  }

  createTicketResponseChat(payload) {
    const formData = new FormData();
    // formData.append("StaffId", payload.StaffId);
    formData.append("Message", payload.Message);
    formData.append("TicketId", payload.TicketId);
    formData.append("PriorityLevelId", payload.PriorityLevelId);
    formData.append("AttachmentFile", payload.AttachmentFile);

    return this.http.post(
      `${environment.API_URL}/${endpoints.createTicketResponseChat}`,
      formData
    );
  }

  routeTicket(payload): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.routeTicket}`,
      payload
    );
  }

  getTicketResponseChat(id) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getTicketResponseChat}/${id}/unpaginated`
    );
  }

  getTicketCategories() {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.ticketCategoriesUnpaginated}`
    );
  }

  loadCorporateCustomers() {
    // individual?
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.customerManagement}/2`
    );
  }

  loadIndividualCustomers() {
    // individual?
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.customerManagement}/1`
    );
  }

  loadExistingTickets() {
    return this.http.get(
      `${environment.API_URL}/${endpoints.tickets}/TicketCategory/Delete`
    );
  }

  loadCustomersAndTicketCats() {
    const customers = this.loadIndividualCustomers();
    const ticketCats = this.getTicketCategories();
    const serviceLevels = this.loadServiceLevels();
    const keywords = this.getAllMacroKeywords();

    return forkJoin([customers, ticketCats, serviceLevels, keywords]).pipe(
      map((res: any) => {
        console.log(res);
        return {
          customers: res[0].objectValue,
          ticketCats: res[1].objectValue,
          serviceLevels: res[2].objectValue,
          keywords: res[3].objectValue,
        };
      })
    );
  }

  getAllMacroKeywords(): Observable<ServerResponse> {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.keywords}`
    );
  }

  loadServiceLevels() {
    return this.http.get(`${environment.API_URL}/${endpoints.serviceLevel}`);
  }

  loadTicketsByCustomerId(id) {
    return this.http
      .get(`${environment.API_URL}/${endpoints.ticketByCustomerId}/${id}`)
      .pipe(
        map((res: any) => {
          return res.objectValue;
        })
      );
  }

  createExistingUserTicket(payload) {
    const formData = new FormData();
    Object.entries(payload).forEach((o: any) => formData.append(o[0], o[1]));
    return this.http
      .post(`${environment.API_URL}/${endpoints.tickets}`, formData)
      .pipe(map((res) => res));
  }

  createNewUserTicket(payload) {
    let formData = new FormData();
    formData.append("CustomerRequest.FullName", payload.FullName);
    formData.append("CustomerRequest.EmailAddress", payload.EmailAddress);
    formData.append("CustomerRequest.PhoneNumber", payload.PhoneNumber);
    formData.append("CustomerRequest.CustomerType", payload.CustomerType);
    formData.append(
      "ExistingTicketHistoriesIds",
      JSON.stringify(payload.ExistingTicketHistoriesIds)
    );

    Object.entries(payload).forEach((o: any) => {
      if (
        o[0] != "FullName" &&
        o[0] != "EmailAddress" &&
        o[0] != "PhoneNumber" &&
        o[0] != "CustomerType" &&
        o[0] != "ExistingTicketHistoriesIds"
      )
        formData.append(o[0], o[1]);
    });
    return this.http
      .post(`${environment.API_URL}/${endpoints.tickets}`, formData)
      .pipe(map((res) => res));
  }

  getTicketByTicketStatusId(pageNumber, pageSize, ticketStatusId) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.getTicketByStatusId}/${ticketStatusId}/paginated`,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  getTicketCount() {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getTicketCount}`
    );
  }

  createFeedbackTicket(payload) {
    let formData = new FormData();

    Object.entries(payload).forEach((each: any) => {
      formData.append(each[0], each[1]);
    });
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/Feedback`,
      formData
    );
  }

  markTicketAsResolved(payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/TicketStatus/Update`,
      payload
    );
  }

  getAllTicketFeedbacks(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.getAllTicketFeedbacks}`,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  getTicketByTicketStatusIdAnalytics(pageNumber, pageSize, ticketStatusId) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.getTicketByStatusIdAnalytics}/${ticketStatusId}`,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }
  getTicketCountReport() {
    return this.http.get(
      `${environment.API_URL}/${endpoints.getTicketStatusCountForAnalytics}`
    );
  }

  fetchSplittedTickets(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.getSplittedTickets}`,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  fetchMergedTickets(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.getMergedTickets}`,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  getOneMergedTicket(mergedTicketParentId) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getOneMergedTicket}/${mergedTicketParentId}`
    );
  }
  getOneSplittedTicket(splittedTicketParentId) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getOneSplittedTicket}/${splittedTicketParentId}`
    );
  }

  getMergedTicketParent(ticketId) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getMergedTicketParent}/${ticketId}`
    );
  }
  getSplittedTicketParent(ticketId) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getSplittedTicketParent}/${ticketId}`
    );
  }

  getCustomerTicketsByStaff(pageNumber, pageSize, customerId) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.getCustomerTicketsByStaff}/${customerId}`,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  getAllTicketsCategoryList(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.getAllTicketsCategoryList}`,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  addNewTicketRoutePath(payload): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.ticketRoute}`,
      payload
    );
  }

  deleteTicketRoutePath(payload: Array<any>): Observable<any> {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.ticketRoute}/Delete`,
      payload
    );
  }
  getAllTicketRoutePath(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(`${environment.API_URL}/${endpoints.ticketRoute}`, {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  getAllTicketRoutePathUnpaginated() {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.ticketRoute}`
    );
  }
  ticketComment(payload) {
    const formData = new FormData();
    formData.append("Comment", payload.Comment);
    formData.append("TicketId", payload.TicketId);
    formData.append("Attachment", payload.Attachment);
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.ticketComment}`,
      formData
    );
  }
  ticketAutoRoute(payload) {
    const formData = new FormData();
    formData.append("Comments", payload.Comments);
    formData.append("TicketId", payload.TicketId);
    formData.append("AttachmentFile", payload.AttachmentFile);
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.ticketAutoRoute}`,
      formData
    );
  }
  convertTicket(payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.changeTicketRouteType}`,
      payload
    );
  }
  searchTicketCategory(searchParams, page, size) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("pageNumber", page)
      .set("pageSize", size);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.searchTicketCategory}/${searchParams}`,
        { observe: "response", params }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  updateTicketRoutePathDetail(id, payload) {
    return this.http.put<ServerResponse>(
      `${environment.API_URL}/${endpoints.ticketRoute}/${id}`,
      payload
    );
  }
  getCustomerTopTicket(ticketId) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.tickets}/GetTopTickets/${ticketId}`
    );
  }
}
