import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { GlobalService } from 'src/app/_services/global.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();
  token: any;
  formSubmitted = false;
  newPasswordForm: FormGroup;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    private global: GlobalService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getToken();
    this.createNewPasswordForm();
  }

  createNewPasswordForm() {
    this.newPasswordForm = this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(8)]],
      cPassword: ['', Validators.required]
    });
  }

  passwordMatchChecker() {
    return this.newPasswordForm.get('Password').value === this.newPasswordForm.get('cPassword').value;
  }

  getToken() {
    this.sub.add(
      this.activeRoute.paramMap.subscribe(paramMap => {
        if (paramMap.has('token')) {
          this.token = paramMap.get('token');
        } else {
          this.router.navigate(['login']);
        }
      })
    );
  }

  validateForm() {
    this.formSubmitted = true;
    const payload = {
      NewPassword: this.newPasswordForm.get('Password').value,
      PasswordResetLinkToken: this.token
    };
    if (!this.newPasswordForm.invalid) {
      this.global.showSpinner();
      this.sub.add(
        this.auth.setNewUserPassword(payload).subscribe(
          {
            next: result => {
              this.global.hideSpinnerWithSuccess();
              this.global.handleResultManipulation(result, null);
            },
            error: e => {
              this.global.hideSpinnerWithError();
              this.global.handleAuthErrors(e);
            }
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
