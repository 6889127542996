import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminNavbarComponent } from "./admin-navbar/admin-navbar.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker/";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgSelectModule } from "@ng-select/ng-select";
import { SideDrawerComponent } from "../utils/side-drawer/side-drawer.component";
import { SideDrawerTriggerDirective } from "../directives/side-drawer-trigger.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { RouterModule } from "@angular/router";
import { SystemNavigationComponent } from "../actors/admin/system/system-navigation/system-navigation.component";
import { CustomerNavbarComponent } from "./customer-navbar/customer-navbar.component";
import { IntUserNavbarComponent } from "./int-user-navbar/int-user-navbar.component";
import { CustomerFooterComponent } from "./customer-footer/customer-footer.component";
import { ModalTriggerDirective } from "../directives/modal-trigger.directive";
import { AyudaModalComponent } from "../utils/ayuda-modal/ayuda-modal.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { NotificationComponent } from "../actors/admin/notification/notification.component";
import { AlertComponent } from "./alert/alert.component";
import { SafePipe } from '../_services/social-integration/safePipe.pipe';
import { CustomerLastThreeTicketsComponent } from './customer-last-three-tickets/customer-last-three-tickets.component';

@NgModule({
  declarations: [
    AdminNavbarComponent,
    CustomerNavbarComponent,
    IntUserNavbarComponent,
    CustomerFooterComponent,
    SideDrawerComponent,
    SideDrawerTriggerDirective,
    SystemNavigationComponent,
    ModalTriggerDirective,
    AyudaModalComponent,
    NotificationComponent,
    AlertComponent,
    SafePipe,
    CustomerLastThreeTicketsComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    RouterModule,
    ImageCropperModule,
  ],
  exports: [
    CustomerNavbarComponent,
    AdminNavbarComponent,
    IntUserNavbarComponent,
    SystemNavigationComponent,
    CustomerFooterComponent,
    SideDrawerComponent,
    SideDrawerTriggerDirective,
    NgxSpinnerModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    RouterModule,
    ModalTriggerDirective,
    AyudaModalComponent,
    ImageCropperModule,
    BsDatepickerModule,
    AlertComponent,
    CustomerLastThreeTicketsComponent
  ],
})
export class SharedComponentsModule {}
