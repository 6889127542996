import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ResponseCodes } from "../models/ResponseCodes.enum";
import { ServerResponse } from "../models/server-response";
import { Router } from "@angular/router";
import { AlertifyService } from "./alertify.service";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  responseCodes = ResponseCodes;

  constructor(
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private alertify: AlertifyService
  ) {}

  // Handle errors and return the right message to the user.

  // Handle spinners and toastrs
  showSpinner() {
    this.spinner.show();
  }
  hideSpinner() {
    this.spinner.hide();
  }

  hideSpinnerWithSuccess(message?: string) {
    this.spinner.hide();
    message
      ? this.alertify.success(message)
      : this.alertify.success("Action Successful");
  }

  hideSpinnerWithError(message?: string) {
    this.spinner.hide();
    if (message) {
      this.alertify.error(message);
    }
  }

  popError(message?: string) {
    message
      ? this.alertify.error(message)
      : this.alertify.error("There seems to be an error somewhere!");
  }

  popFormError() {
    this.alertify.error(
      `You've got some errors on your form, please double check!`
    );
  }

  handleAuthErrors(error) {
    console.log(error);
    this.spinner.hide();
    if (error.status === 400) {
      const errorResult: ServerResponse = error.error;
      switch (errorResult.statusCode) {
        case this.responseCodes.NotFound:
          this.alertify.error(
            "Email and password combination error, please check your credentials!"
          );
          break;
        case this.responseCodes.MailFailure:
          this.alertify.error("Email not sent, please try again!");
          break;
        case this.responseCodes.NewPasswordError:
          this.alertify.error(
            "There is an error with the password you selected, please use a different one!"
          );
          break;
        case this.responseCodes.PreviousPasswordStorageError:
          this.alertify.error(
            "You have previously used and forgotten a similar password, please use an entirely new password!"
          );
          break;
        case this.responseCodes.SignInError:
          this.alertify.error(
            "Email and password combination error, please check your credentials!"
          );
          break;
        case this.responseCodes.EmailAlreadyConfirmed:
          this.alertify.error(
            "Email already confirmed, please enter your details to login!"
          );
          break;
        case this.responseCodes.ObjectExists:
          this.alertify.error(
            "Email address already in use, Go to login page to access your account!"
          );
          break;
        case this.responseCodes.NotSucceeded:
          this.alertify.error("Sorry, An unexpected error occured!");
          break;
        case this.responseCodes.SaveError:
          this.alertify.error(
            "Sorry, An unexpected error occured, Please try again!"
          );
          break;
        default:
          break;
      }
    }
  }

  handleGeneralErrors(error) {
    this.spinner.hide();
    console.log("After spinner");
    if (error.status === 0) {
      this.alertify.error(
        "Error! Could not establish a connection to the server!"
      );
    } else if (error.status === 400) {
      const errorResult: ServerResponse = error.error;
      switch (errorResult.statusCode) {
        case this.responseCodes.NotFound:
          this.alertify.error("The requested resource could not be found!");
          break;
        case this.responseCodes.SaveError:
          this.alertify.error(
            "An error occurred, operation not completed, please try again!"
          );
          break;
        case this.responseCodes.NoUserWithRoutingCriteria:
          this.alertify.error(
            "There seem to be no user in the specified department and unit with that support level."
          );
          break;
        case this.responseCodes.ObjectExists:
          this.alertify.error(
            "The resource already exists, please check your input and make necessary changes!"
          );
          break;
        case this.responseCodes.NewPasswordError:
          this.alertify.error(
            "There is an error with the password you selected, please use a different one!"
          );
          break;
        case this.responseCodes.PreviousPasswordStorageError:
          this.alertify.error(
            "You have previously used and forgotten a similar password, please use an entirely new password!"
          );
          break;
        case this.responseCodes.SignInError:
          this.alertify.error(
            "Email and password combination error, please check your credentials!"
          );
          break;
        case this.responseCodes.EmailAlreadyConfirmed:
          this.alertify.error(
            "Email already confirmed, please enter your details to login!"
          );
          break;
        case this.responseCodes.ObjectExists:
          this.alertify.error(
            "Email address already in use, Go to login page to access your account!"
          );
          break;
        case this.responseCodes.UserNotAllowed:
          this.alertify.error(
            "User Not Allowed Because He/She doesnt belong to Certain Support Level and Department!"
          );
          break;
        case this.responseCodes.TierOneSupportLevelExistingError:
          this.alertify.error(
            "Support level is currently set as Tier One, Please unset that before setting a new one"
          );
        default:
          this.alertify.error("Sorry an unexpected error occured!");
          break;
      }
    }
  }

  // Handle email verification such that I can use it from any place

  handleEmailVerification(emailAddress: string, routeValue?: string) {
    const payload = { EmailAddress: emailAddress };
    this.showSpinner();
    this.auth.resendEmailVerificationLink(payload).subscribe({
      next: (result) => {
        this.hideSpinnerWithSuccess();
        if (routeValue) {
          this.router.navigate([routeValue], {
            state: {
              data: { emailAddress },
            },
          });
        }
      },
      error: (e) => {
        this.hideSpinnerWithError();
        this.handleAuthErrors(e);
        return false;
      },
    });
  }
  handleForgotPasswordEmail(emailAddress: string, routeValue?: string) {
    const payload = { EmailAddress: emailAddress };
    this.showSpinner();
    this.auth.sendPasswordResetEmailToken(payload).subscribe({
      next: (result) => {
        this.hideSpinnerWithSuccess();
        if (routeValue) {
          this.router.navigate([routeValue], {
            state: {
              data: { emailAddress },
            },
          });
        }
      },
      error: (e) => {
        this.hideSpinnerWithError();
        this.handleAuthErrors(e);
        return false;
      },
    });
  }

  handleResultManipulation(result: ServerResponse, returnUrl) {
    // Store token and take user to the dashboard
    localStorage.setItem("token", result.objectValue.token);
    localStorage.setItem(
      "userDetails",
      JSON.stringify(result.objectValue.userProfileInformation)
    );
    localStorage.setItem("userLogin", JSON.stringify(result.objectValue.user));
    // Check user type and direct to the correct dashboard
    if (result.objectValue.user.userType === 2) {
      // redirect to customer dashboard
      returnUrl == null
        ? this.router.navigate(["customer"])
        : this.router.navigate([returnUrl]);
    } else {
      // redirect to staff dashboard
      returnUrl == null
        ? this.router.navigate(["admin"])
        : this.router.navigate([returnUrl]);
    }
  }

  handleVerifyEmailResultManipulation(result: ServerResponse) {
    const returnedObject = result.objectValue;
    if (returnedObject.user.userType === 1) {
      localStorage.setItem(
        "userToVerify",
        JSON.stringify(result.objectValue.userProfileInformation)
      );
      this.router.navigate(["/completeProfile"]);
    } else {
      this.handleResultManipulation(result, null);
    }
  }

  handleHttpErrors(error) {
    if (error.status === 401) {
      this.hideSpinnerWithError("Unauthorized");
      return this.routeAppropriately(error);
    } else if (error.status === 400) {
      this.spinner.hide();
      this.alertify.error(error.error.statusMessage);
      // this.handleGeneralErrors(error);
      return this.routeAppropriately(error);
    } else if (error.status === 0) {
      this.hideSpinnerWithError(
        "Error! Could not establish a connection to the server!"
      );
      return this.routeAppropriately(error);
    } else {
      this.hideSpinnerWithError(
        "An unexpected error occured, please try again!"
      );
      return this.routeAppropriately(error);
    }
  }

  routeAppropriately(error) {
    if (!this.auth.loggedIn()) {
      return of(error);
    } else {
      if (this.auth.getUserType() === "1") {
        return of(error);
      } else {
        return of(error);
      }
    }
  }

  handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    this.router.navigate(["login"]);
  }
}
