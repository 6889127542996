import { Router } from "@angular/router";
import { NotificationService } from "./../../../_services/notification.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalService } from "src/app/_services/global.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();
  notifications: any[] = [];
  unreadNotifications: any[] = [];
  constructor(
    private notificationService: NotificationService,
    private _global: GlobalService,
    private router: Router
  ) {}
  ngOnInit() {
    this.getAllNotifications();
  }
  getAllNotifications() {
    this._global.showSpinner();
    this.sub.add(
      this.notificationService.getAllNotifications().subscribe({
        next: (res) => {
          this._global.hideSpinner();
          console.log(res);
          this.notifications = res.objectValue;
          this.unreadNotifications = this.notifications.filter((n) => !n.seen);
        },
        error: (error) => {
          console.log(error);
          this._global.hideSpinnerWithError();
        },
      })
    );
  }

  clearAll() {
    this._global.showSpinner();
    this.sub.add(
      this.notificationService.clearAllNotifications().subscribe({
        next: (res) => {
          this._global.hideSpinnerWithSuccess(
            "Notifications deleted successfully"
          );
          this.unreadNotifications = [];
        },
        error: (error) => {
          this._global.handleGeneralErrors(error);
        },
      })
    );
  }
  clear(notificationId) {
    this._global.showSpinner();
    let payload = [{ notificationId: notificationId }];
    this.sub.add(
      this.notificationService.clearSpecificNotification(payload).subscribe({
        next: (res) => {
          this._global.hideSpinnerWithSuccess(
            "Notification deleted successfully"
          );

          this.unreadNotifications = this.unreadNotifications.filter(
            (n) => n.notificationId != notificationId
          );
        },

        error: (error) => {
          this._global.handleGeneralErrors(error);
        },
      })
    );
  }

  view(notification) {
    if (notification.type == 2) {
      this.router.navigate([
        "admin/incident/customer",
        JSON.parse(notification.resourceId)[0],
      ]);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
