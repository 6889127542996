import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent implements OnInit {

  registeredEmail: string;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private global: GlobalService
  ) { }

  ngOnInit() {
    const result = this.activeRoute.paramMap.pipe(map(() => window.history.state.data));
    result.subscribe(data => {
      if (!data) {
        this.router.navigate(['login']);
      } else {
        this.registeredEmail = data['emailAddress'];
      }
    });
  }

  resendEmailConfirmation() {
    // Logic here!!!
    this.global.handleForgotPasswordEmail(this.registeredEmail);
  }

}
