import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/_services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  resetPasswordForm: FormGroup;
  formSubmitted = false;
  sub: Subscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private toastr: ToastrService,
    private global: GlobalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      EmailAddress: ['', [Validators.required, Validators.email]]
    });
  }

  sendResetPasswordLink() {
    this.formSubmitted = true;
    if (!this.resetPasswordForm.invalid) {
      const email = this.resetPasswordForm.get('EmailAddress').value;
      this.global.handleForgotPasswordEmail(email, 'confirmPassword');
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
