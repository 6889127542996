import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StaffsService } from 'src/app/_services/staff-mgt/staffs.service';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/_services/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-staff-profile-completion',
  templateUrl: './staff-profile-completion.component.html',
  styleUrls: ['./staff-profile-completion.component.scss']
})
export class StaffProfileCompletionComponent implements OnInit {
  
  userInfo: any;
  staffFullDetail: any;
  completionStep = 1;
  sub: Subscription = new Subscription();
  newPasswordForm: FormGroup;

  // Image Cropper Related
  imageChangedEvent: any = '';
  croppedImage: any = '';
  backColor = '#000000';
  croppedImageFile: any;

  constructor(
    private router: Router,
    private staffService: StaffsService,
    private global: GlobalService,
    private fb: FormBuilder
  ) { }

  // Remember to remove the userToVerify from the localstorage!

  ngOnInit() {
    this.getAttachedData();
    this.createNewPasswordForm();
  }

  getAttachedData() {
    this.userInfo = JSON.parse(localStorage.getItem('userToVerify'));
    console.log(this.userInfo)
    if (this.userInfo === undefined) {
      this.router.navigate(['login']);
    } else {
      this.getUserCompleteDetail();
    }
  }

  getUserCompleteDetail() {
    this.global.showSpinner();
    this.sub.add(
      this.staffService.getNewSingleStaffDetail(this.userInfo.staffId).subscribe(
        {
          next: response => {
            console.log(response);
            this.global.hideSpinnerWithSuccess(response.statusMessage);
            this.staffFullDetail = response.objectValue.staff;
            console.log(this.staffFullDetail)
          },
          error: e => {
            this.global.hideSpinnerWithError();
            this.global.handleGeneralErrors(e);
          }
        }
      )
    );
  }

  createNewPasswordForm() {
    this.newPasswordForm = this.fb.group({
      OldPassword: ['', Validators.required],
      NewPassword: ['', [Validators.required, Validators.minLength(8)]],
      cPassword: ['', Validators.required],
    });
  }

  passwordMatchChecker() {
    return this.newPasswordForm.get('NewPassword').value === this.newPasswordForm.get('cPassword').value;
  }

  continueToStepTwo() {
    this.completionStep++;
  }

  continueToStepThree() {
    this.completionStep++;
  }

  returnBackOneStep() {
    this.completionStep--;
  }

  completeProfileSetup() {
    const payload = {
      EmailAddress: this.staffFullDetail.emailAddress,
      OldPassword: this.newPasswordForm.get('OldPassword').value,
      NewPassword: this.newPasswordForm.get('NewPassword').value,
      StaffProfilePicture: this.croppedImageFile
    };
    console.log(payload);
    this.global.showSpinner();
    this.sub.add(
      this.staffService.completeStaffSetup(payload).subscribe(
        {
          next: response => {
            this.global.hideSpinnerWithSuccess(response.statusMessage);
            localStorage.removeItem('userToVerify');
            this.global.handleResultManipulation(response, null);
          },
          error: e => {
            this.global.hideSpinnerWithError();
            this.global.handleGeneralErrors(e);
          }
        }
      )
    );
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.continueToStepThree();
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.croppedImageFile = this.dataURLtoFile(this.croppedImage, 'profileImage.png');
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }
  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}

}
