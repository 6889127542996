import { Notification } from "./../../models/notification";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/_services/global.service";
import { SignalRService } from "src/app/_services/signal-r.service";
import { NotificationService } from "src/app/_services/notification.service";

@Component({
  selector: "app-customer-navbar",
  templateUrl: "./customer-navbar.component.html",
  styleUrls: ["./customer-navbar.component.scss"],
})
export class CustomerNavbarComponent implements OnInit {
  userProfilePicture: String;
  userInfo;
  notifications: Notification[] = [];
  unreadNotifications: Notification[] = [];

  constructor(
    private router: Router,
    private global: GlobalService,
    private signalR: SignalRService,
    private _notification: NotificationService
  ) {}

  ngOnInit() {
    this.getUserImage();
    this.fetchUserNotifications();
  }
  fetchUserNotifications() {
    this._notification.getAllNotifications().subscribe((notifications) => {
      this.notifications = notifications.objectValue;
      this.notifications.forEach((notification: Notification) => {
        notification.Seen ? null : this.unreadNotifications.push(notification);
      });
    });
  }
  getUserImage() {
    this.userInfo = JSON.parse(localStorage.getItem("userDetails"));
    this.userInfo.customerProfilePictureUrl
      ? (this.userProfilePicture = this.userInfo.customerProfilePictureUrl)
      : (this.userProfilePicture = "/assets/img/profile.png");
  }

  logUserOut() {
    this.global.showSpinner();
    this.signalR.disconnect();
    setTimeout(() => {
      this.global.handleLogout();
      this.global.hideSpinnerWithSuccess(
        `You have successfully logged out of the system.`
      );
    }, 1000);
  }
  onRecieveNotification(notification) {
    this.unreadNotifications.push(notification);
  }
}
