import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-ayuda-modal',
  templateUrl: './ayuda-modal.component.html',
  styleUrls: ['./ayuda-modal.component.scss']
})
export class AyudaModalComponent implements OnInit {
  @ViewChild('close',{ static: false }) close: ElementRef<HTMLElement>;
  @Input() headerTitle: string;
  @Input() submitName: string;
  @Input() elementId: string;
  @Input() modalLg: boolean;
  @Input() centered: boolean;
  @Input() styles = {
    modalBodyHeight: '330px',
    modalContentWidth: '500px'
  }
  @Input() noPaddingBottom = false;

  constructor() { }

  ngOnInit() {
    document.body.addEventListener('keydown', (e) => {
      if(e.keyCode == 27) {
        event.preventDefault();
        this.close.nativeElement.click();
      }
    })
  }
}
