import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserReg } from "src/app/models/UserReg";
import { ResponseCodes } from "src/app/models/ResponseCodes.enum";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { ServerResponse } from "src/app/models/server-response";
import { SwalConfig, SwalConfirmation } from "../../utils/swal";
import { GlobalService } from "src/app/_services/global.service";
import { AlertifyService } from "src/app/_services/alertify.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild("passwordInput", { static: false })
  passwordInput: ElementRef<HTMLElement>;
  logInForm: FormGroup;
  formSubmitted = false;
  userData: UserReg;
  serverResponseCodes = ResponseCodes;
  sub: Subscription = new Subscription();
  hidden = true;
  returnUrl;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private globalService: GlobalService,
    private alertify: AlertifyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.createLoginForm();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || null;
  }

  toggleHidden() {
    const attr = this.passwordInput.nativeElement.getAttribute("type");
    if (attr === "password") {
      this.passwordInput.nativeElement.setAttribute("type", "text");
    } else {
      this.passwordInput.nativeElement.setAttribute("type", "password");
    }
    this.hidden = !this.hidden;
  }

  createLoginForm() {
    this.logInForm = this.formBuilder.group({
      EmailAddress: ["", [Validators.required, Validators.email]],
      Password: ["", Validators.required],
    });
  }

  validateForm() {
    this.formSubmitted = true;
    const payload = { ...this.logInForm.value };
    if (this.logInForm.valid) {
      this.spinner.show();
      this.sub.add(
        this.authService.authorizeAccess(payload).subscribe({
          next: (result) => {
            this.spinner.hide();
            if (!result.objectValue.user.emailConfirmed) {
              SwalConfig.fire({
                title: "Account Pending Verification",
                icon: "info",
                html: `<p>It seems your account has not been verified, click continue to get a new verification email?`,
              }).then((response) => {
                if (response.value) {
                  console.log(response.value);
                  if (result.objectValue.user.userType === 1) {
                    this.globalService.handleVerifyEmailResultManipulation(
                      result
                    );
                  } else {
                    // Resend verification email then route below
                    this.globalService.handleEmailVerification(
                      payload.EmailAddress,
                      "emailConfirmation"
                    );
                  }
                }
              });
            } else {
              this.globalService.handleResultManipulation(
                result,
                this.returnUrl
              );
            }
          },
          error: (e) => {
            if (e.status === 0) {
              this.globalService.hideSpinnerWithError();
              this.alertify.error(
                "Error! Could not establish a connection to the server!"
              );
            } else {
              // this.globalService.hideSpinnerWithError();
              this.globalService.handleAuthErrors(e);
            }
          },
        })
      );
    } else {
      this.globalService.popFormError();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
