import { endpoints } from "src/app/config/endpoint";
import { ServerResponse } from "./../models/server-response";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private http: HttpClient) {}
  getAllNotifications() {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getAllNotifications}`
    );
  }
  getOneNotification(id) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.getOneNotification}/${id}`
    );
  }
  deleteNotification(payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.deleteNotification}`,
      payload
    );
  }
  clearSpecificNotification(payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.clearSpecificUnread}`,
      payload
    );
  }
  clearAllNotifications() {
    let payload = {};
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.clearAllUnread}`,
      payload
    );
  }
}
