export class PagedResponse<T> {
    result: T;
    paginationInfo: PaginationInfo;
}

export class PaginationInfo {
    totalItems: number; // total collection item (determined by the server)
    totalPages: number; // total possible pages (determined by the server)
    currentPage: number; // currently requested page (determined by the client)
    itemsPerPage: number; // items per page (determined by the client)
    previousPage: number;
}