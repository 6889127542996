import { ToastrService } from "ngx-toastr";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { SignalRService } from "src/app/_services/signal-r.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Output() notification: EventEmitter<number> = new EventEmitter();
  sub: Subscription = new Subscription();
  constructor(
    private _toastr: ToastrService,
    private signalR: SignalRService
  ) {}
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    this.signalR.startConnection();
    console.log("in");
    this.sub.add(
      this.signalR.checNotification.subscribe({
        next: (res) => {
          console.log(res);
          console.log("here and here again");
          this._toastr.success("new notification recieved");

          this.playAudio();
          this.notification.emit(res);
        },
      })
    );
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/sounds/notification.mp3";
    audio.load();
    audio.play();
  }
}
