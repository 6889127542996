import { Component, HostListener } from "@angular/core";
import { SignalRService } from "./_services/signal-r.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @HostListener("window:unload", ["$event"])
  unloadNotification($event: any) {
    this.signalR.disconnect();
  }
  title = "Ayuda-Frontend";
  color = "red";
  constructor(private signalR: SignalRService) {}
  moveUp() {
    window.scroll(0, 0);
  }
}
