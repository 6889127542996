import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ConfirmationPageComponent } from './auth/confirmation-page/confirmation-page.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { EmailConfirmationComponent } from './auth/email-confirmation/email-confirmation.component';
import { SignupComponent } from './auth/signup/signup.component';

import { SharedComponentsModule } from './containers/shared.module';

import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { AuthGuard } from './guards/auth.guard';
import { StaffProfileCompletionComponent } from './auth/staff-profile-completion/staff-profile-completion.component';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'signUp', component: SignupComponent, canActivate: [AuthGuard] },
  { path: 'emailConfirmation', component: EmailConfirmationComponent },
  // { path: 'emailConfirmation', component: EmailConfirmationComponent, canActivate: [AuthGuard] },
  { path: 'forgotPassword', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: 'confirmPassword', component: ConfirmationPageComponent, canActivate: [AuthGuard] },
  { path: 'ResetPassword/:token', component: ResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'UserVerifyEmail/:token', component: VerifyEmailComponent, canActivate: [AuthGuard] },
  { path: 'completeProfile', component: StaffProfileCompletionComponent, canActivate: [AuthGuard] },
  { path: 'admin',
    loadChildren: () => import('./actors/admin/admin.module').then(m => m.AdminModule),
    canLoad: [AuthGuard],
    data: {
      expectedUserType: ['1']
    }
  //  canActivate: [AuthGuard]
  },
  { path: 'customer',
    loadChildren: () => import('./actors/customer/customer.module').then(m => m.CustomerModule),
    canLoad: [AuthGuard],
    data: {
      expectedUserType: ['2']
    }
  }
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    SharedComponentsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
