import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../_services/authentication.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { GlobalService } from "../_services/global.service";
import { Location } from "@angular/common";
import { SwalConfirmation } from "../utils/swal";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {
  externalRoutes = [
    "login",
    "signUp",
    "emailConfirmation",
    "forgotPassword",
    "confirmPassword",
    "completeProfile",
    "ResetPassword/:token",
    "UserVerifyEmail/:token",
  ];
  constructor(
    private auth: AuthenticationService,
    private jwt: JwtHelperService,
    private router: Router,
    private global: GlobalService,
    private location: Location
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const expectedUserType = route.data.expectedUserType;
    let returnUrl = "";
    segments.map((e) => {
      returnUrl += `${e.path}/`;
    });
    if (!this.auth.loggedIn()) {
      if (this.auth.tokenHasExpired) {
        this.global.hideSpinnerWithError(
          "Your session has expired, please log back in!"
        );
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: returnUrl },
        });
        return false;
      } else {
        this.global.hideSpinnerWithError(
          "You need to be logged in to access that resource!"
        );
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: returnUrl },
        });
        return false;
      }
    }
    const userType = this.auth.getUserType();
    if (!expectedUserType.includes(userType)) {
      SwalConfirmation.fire({
        title: "Access Denied",
        icon: "error",
        html: `<p class = "text-center">Unauthorized. Please head back to where you were coming from. Thank you!</p>`,
      }).then((res) => {
        window.location.reload()
        // this.location.back();
      // this.router.navigate(["/login"]);
      });
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = localStorage.getItem("token");
    const tokenDecoded = this.jwt.decodeToken(token);
    if (this.externalRoutes.includes(next.routeConfig.path)) {
      if (this.auth.loggedIn()) {
        const userType = this.auth.getUserType();
        if (userType == "1") {
          this.router.navigate(["/admin"]);
          return false;
        } else {
          this.router.navigate(["/customer"]);
          return false;
        }
      } else {
        return true;
      }
    }

    if (!this.auth.loggedIn()) {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    } else {
      return true;
    }
  }
}
