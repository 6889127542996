import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ResponseCodes } from "src/app/models/ResponseCodes.enum";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { GlobalService } from "src/app/_services/global.service";

@Component({
  selector: "app-email-confirmation",
  templateUrl: "./email-confirmation.component.html",
  styleUrls: ["./email-confirmation.component.scss"],
})
export class EmailConfirmationComponent implements OnInit {
  registeredEmail = "";

  confirmationForm: FormGroup;
  formSubmitted = false;
  serverResponseCodes = ResponseCodes;
  sub: Subscription = new Subscription();
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private global: GlobalService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    const result = this.activeRoute.paramMap.pipe(
      map(() => window.history.state.data)
    );
    result.subscribe((data) => {
      if (!data) {
        this.router.navigate(["login"]);
      } else {
        this.registeredEmail = data["emailAddress"];
      }
    });
    this.createConfirmForm();
  }

  resendEmailConfirmation() {
    // Logic here!!!
    this.global.handleEmailVerification(this.registeredEmail);
  }

  createConfirmForm() {
    this.confirmationForm = this.formBuilder.group({
      code: ["", Validators.required],
    });
  }

  validateForm() {
    this.formSubmitted = true;
    if (this.confirmationForm.valid) {
      this.global.showSpinner();
      const payload = {
        otp: this.confirmationForm.value.code,
        emailAddress: this.registeredEmail,
      };
      this.sub.add(
        this.authService.sendOTP(payload).subscribe({
          next: (result) => {
            this.formSubmitted = false;
            if (result.statusCode === this.serverResponseCodes.Success) {
              this.global.hideSpinnerWithSuccess(result.statusMessage);
              this.global.handleResultManipulation(result, null);
            }
          },
          error: (e) => {
            this.global.hideSpinnerWithError();
            this.global.handleAuthErrors(e);
          },
        })
      );
    }
  }
}
