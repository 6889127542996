export enum ResponseCodes {
  Success = 0,
  MailFailure = 1,
  NotFound = 2,
  ObjectNull = 3,
  SaveError = 4,
  SaveNoRowAffected = 5,
  NotSucceeded = 6,
  ObjectExists = 7,
  BadRequest = 8,
  SignInError = 9,
  EmailAlreadyConfirmed = 10,
  PreviousPasswordStorageError = 11,
  NewPasswordError = 12,
  TierOneSupportLevelExistingError = 21,
  NoUserWithRoutingCriteria = 23,
  UserNotAllowed = 28,
}
