import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserReg } from '../models/UserReg';
import { Observable } from 'rxjs';
import { ServerResponse } from '../models/server-response';
import { environment } from 'src/environments/environment';
import { endpoints } from '../config/endpoint';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private jwt: JwtHelperService) { }

  customerRegistration(CustomerData: UserReg): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.registerCustomer}`, CustomerData);
  }

  authorizeAccess(payload): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.login}`, payload);
  }

  resendEmailVerificationLink(payload): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.resendEmailVerification}`, payload);
  }

  verifyCustomersToken(payload): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.customerTokenVerification}`, payload);
  }

  sendPasswordResetEmailToken(payload): Observable<ServerResponse> {
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.sendPasswordResetEmail}`, payload);
  }

  setNewUserPassword(payload) {
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.setNewUserPassword}`, payload);
  }

  loggedIn(): boolean {
    const token = localStorage.getItem('token');
    return (
      localStorage.getItem('token') !== null &&
      !this.jwt.isTokenExpired(token)
    );
  }

  tokenHasExpired(): boolean {
    const token = localStorage.getItem('token');
    return token && this.jwt.isTokenExpired(token);
  }

  getUserType() {
    const token = localStorage.getItem('token');
    return this.jwt.decodeToken(token).UserType;
  }


  getUserRoles() {
    const token = localStorage.getItem('token');
    return this.jwt.decodeToken(token).role;
  }
  changeUserPassword(payload){
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.changePassword}`,payload)
  }
  sendOTP(payload){
    return this.http.post<ServerResponse>(`${environment.API_URL}/${endpoints.sendOTP}`,payload)
  }
}
