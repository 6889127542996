import Swal from 'sweetalert2';

export const SwalConfig = Swal.mixin({
    showCancelButton: true,
    confirmButtonText: 'Continue',
    cancelButtonText: 'Cancel',
    allowOutsideClick: false
})

export const SwalYesNo = Swal.mixin({
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false
})

export const SwalConfirmation = Swal.mixin({
    showCancelButton: false,
    confirmButtonText: 'Close',
    allowOutsideClick: false
})