import { NotificationService } from "./../../_services/notification.service";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/_services/global.service";
import { SignalRService } from "src/app/_services/signal-r.service";

@Component({
  selector: "app-admin-navbar",
  templateUrl: "./admin-navbar.component.html",
  styleUrls: ["./admin-navbar.component.scss"],
})
export class AdminNavbarComponent implements OnInit {
  @Input() themeColor: string;
  unReadNotifications: any[] = [];

  notifications: any[] = [];
  userProfilePicture: string;
  userInfo;
  UserRole;

  constructor(
    private router: Router,
    private global: GlobalService,
    private notificationService: NotificationService,
    private signalR: SignalRService
  ) {}

  ngOnInit() {
    this.getUserImage();
    this.fetchUserNotifications();
  }
  fetchUserNotifications() {
    this.notificationService
      .getAllNotifications()
      .subscribe((notifications) => {
        this.notifications = notifications.objectValue;
        this.notifications.forEach((notification) => {
          notification.seen
            ? null
            : this.unReadNotifications.push(notification);
        });
      });
  }
  onRecieveNotification(notification) {
    this.unReadNotifications.push(notification);
  }
  getUserImage() {
    this.userInfo = JSON.parse(localStorage.getItem("userDetails"));
    console.log(this.userInfo);
    this.userInfo.staffProfilePictureUrl
      ? (this.userProfilePicture = this.userInfo.staffProfilePictureUrl)
      : (this.userProfilePicture = "/assets/img/profile.png");
    this.UserRole = JSON.parse(
      localStorage.getItem("userLogin")
    ).userRoles[0].role.name;
  }

  goToCustomerMgt() {
    this.router.navigate(["admin/customers"]);
  }

  goToUserMgt() {
    this.router.navigate(["admin/user"]);
  }

  gotToSystemSetup() {
    this.router.navigate(["admin/system/support-level"]);
  }

  goToDashboard() {
    this.router.navigate(["admin"]);
  }

  logUserOut() {
    this.global.showSpinner();
    this.signalR.disconnect();
    setTimeout(() => {
      this.global.handleLogout();
      this.global.hideSpinnerWithSuccess(
        `You have successfully logged out of the system.`
      );
    }, 1000);
  }
}
