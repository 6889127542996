import { Injectable } from "@angular/core";
import { ServerResponse } from "src/app/models/server-response";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { PagedResponse } from "src/app/models/pagedResponse";
import { environment } from "src/environments/environment";
import { endpoints } from "src/app/config/endpoint";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StaffsService {
  constructor(private http: HttpClient) {}

  getAllStaffsPaginated(pageNumber, pageSize) {
    const paginatedResponse: PagedResponse<any> = new PagedResponse<any>();
    const params = new HttpParams()
      .set("PageNumber", pageNumber)
      .set("PageSize", pageSize);
    return this.http
      .get<ServerResponse>(
        `${environment.API_URL}/${endpoints.staffManagement}`,
        { observe: "response", params }
      )
      .pipe(
        map((response) => {
          paginatedResponse.result = response.body.objectValue;
          if (response.headers.get("Pagination") != null) {
            paginatedResponse.paginationInfo = JSON.parse(
              response.headers.get("Pagination")
            );
          }
          return paginatedResponse;
        })
      );
  }

  getAllStaffUnpaginated() {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/unpaginated`
    );
  }

  addStaff(payload) {
    const headers = new HttpHeaders().set("StaffRegistrationType", "1");
    // const formData = new FormData().append('Staff', payload.Staff);
    // const sendIt = {staff: payload}
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/Register`,
      payload,
      { headers }
    );
  }

  getSingleStaffDetail(id) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/${id}`
    );
  }
  getNewSingleStaffDetail(id){
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/NotLoggedIn/${id}`
    );
  }
  completeStaffSetup(payload) {
    const formData: FormData = new FormData();
    formData.append("EmailAddress", payload.EmailAddress);
    formData.append("NewPassword", payload.NewPassword);
    formData.append("OldPassword", payload.OldPassword);
    formData.append("StaffProfilePicture", payload.StaffProfilePicture);
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/SetPassword`,
      formData
    );
  }
  updateStaffDetail(id, payload) {
    return this.http.put<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/admin/${id}`,
      payload
    );
  }
  deleteStaff(payload: Array<any>): Observable<any> {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/Delete`,
      payload
    );
  }

  downloadPDF(payload) {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/api/General/ModuleFile/${payload}`
    );
  }

  loadStaffFromAPI(url, requestType, payload?) {
    if (requestType == "Post") return this.http.post<ServerResponse>(url, {});
    else return this.http.get<ServerResponse>(url);
  }

  registerStaffFromAPI(payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/api/Staff/Register`,
      { staff: payload }
    );
  }

  changeProfilePicture(payload) {
    const formData: FormData = new FormData();
    formData.append("StaffProfilePicture", payload.StaffProfilePicture);
    return this.http.put<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/Picture`,
      formData
    );
  }

  getStaffSupportLevelCount() {
    return this.http.get<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffSupportLevelCount}`
    );
  } 
  SearchStaff(payload) {
    return this.http.post<ServerResponse>(
      `${environment.API_URL}/${endpoints.staffManagement}/Search/${payload}`,
      {payload}
    );
  }
}
