import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { GlobalService } from "../_services/global.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private helper: GlobalService,
    private jwt: JwtHelperService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = localStorage.token;
    request = request.clone({
      headers: request.headers.set("Authorization", `Bearer ${authToken}`),
    });
    return next.handle(request).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        this.helper.handleHttpErrors(error);
        return of(error);
      })
    );
  }
}
