import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { group } from "@angular/animations";
import { UserReg } from "src/app/models/UserReg";
import { Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ResponseCodes } from "src/app/models/ResponseCodes.enum";
import { GlobalService } from "src/app/_services/global.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit, OnDestroy {
  @ViewChild("passwordInput",{ static: false }) passwordInput: ElementRef<
    HTMLElement
  >;
  signUpForm: FormGroup;
  formSubmitted = false;
  userData: UserReg;
  serverResponseCodes = ResponseCodes;
  sub: Subscription = new Subscription();
  hidden = true;
  chidden = true;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.createSignUpForm();
  }

  
  toggleHidden() {
    const attr = this.passwordInput.nativeElement.getAttribute('type')
    if (attr === 'password') {
      this.passwordInput.nativeElement.setAttribute('type','text')
    } else {
      this.passwordInput.nativeElement.setAttribute('type','password')
    }
    this.hidden = !this.hidden;
  }
  createSignUpForm() {
    this.signUpForm = this.formBuilder.group({
      FullName: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(^[A-Za-z]{3,16})([ ]{0,1})(([A-Za-z]{3,16})|(([A-Za-z]{3,16})([ ]{0,1}))|(([A-Za-z]{3,16})([ ]{0,1})([A-Za-z]{3,16})))"
          ),
        ],
      ],
      PhoneNumber: [
        "+234",
        [
          Validators.required,
          Validators.pattern(
            "^[+]([0-9]{3})(((8)(0|1))|((7)(0))|((9)(0)))\\d{8}$"
          ),
        ],
      ],
      EmailAddress: ["", [Validators.required, Validators.email]],
      CustomerType: ["", Validators.required],
      Password: ["", [Validators.required, Validators.minLength(8)]],
      cPassword: ["", Validators.required],
    });
  }

  passwordMatchChecker() {
    return (
      this.signUpForm.get("Password").value ===
      this.signUpForm.get("cPassword").value
    );
  }

  validateFormFields() {
    this.formSubmitted = true;
    if (this.signUpForm.valid && this.passwordMatchChecker()) {
      // this.spinner.show();
      this.globalService.showSpinner();
      this.userData = {
        ...this.signUpForm.value,
      };
      const payload = {
        CustomerType: this.userData.CustomerType,
        EmailAddress: this.userData.EmailAddress,
        FullName: this.userData.FullName,
        Password: this.userData.Password,
        PhoneNumber: this.userData.PhoneNumber,
      };
      this.sub.add(
        this.authService.customerRegistration(payload).subscribe({
          next: (result) => {
            if (result.statusCode === this.serverResponseCodes.Success) {
              this.globalService.hideSpinnerWithSuccess(result.statusMessage);
              this.router.navigate(["emailConfirmation"], {
                state: {
                  data: result.objectValue,
                },
              });
            }
          },
          error: (e) => {
            this.globalService.hideSpinnerWithError();
            this.globalService.handleAuthErrors(e);
          },
        })
      );
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
