import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-navigation',
  templateUrl: './system-navigation.component.html',
  styleUrls: ['./system-navigation.component.scss']
})
export class SystemNavigationComponent implements OnInit {

  closeMenu = true;
  constructor() { }

  ngOnInit() {
  }

}
