export const endpoints = {
  // Auth Module Endpoints
  login: "api/auth/login",
  registerCustomer: "api/Customers/Register",
  resendEmailVerification: "api/auth/ResendUserMobileEmailVerificationCode",
  customerTokenVerification: "api/auth/VerifyUserEmail",
  sendPasswordResetEmail: "api/auth/ResetPassword/SendMail",
  setNewUserPassword: "api/auth/ResetPassword/SetNewPassword",
  changePassword: "api/auth/ChangePassword",
  sendOTP: "/api/Auth/VerifyMobileUserEmail",

  // Setup Module Endpoints
  agentSignature: "api/AgentSignature",
  serviceLevel: "api/ServiceLevel",
  supportLevel: "api/SupportLevels",
  functionalities: "api/RoleManagement/ProjectModules",
  zones: "api/Zone",
  branch: "api/Branch",
  department: "api/Department",
  units: "api/SubUnit",
  searchServiceLevels: "api/ServiceLevel/Search",
  searchSupportLevels: "api/SupportLevels/Search",
  searchBranches: "api/Branch/Search",

  searchTickets: "api/Ticket/Search",
  searchAgentSignatures: "api/AgentSignature/Search",
  searchDepartment: "api/Department/Search",
  searchMacro: "api/Macro/Search",
  searchMacroCategory: "api/MacroCategory/Search",
  searchZones: "api/Zone/Search",
  macroCategory: "api/MacroCategory",
  tickets: "api/Tickets",
  ticketRoute: "api/TicketRoutePaths",
  

  // Customer Management Module
  customerManagement: "api/CustomerManagement",
  customerDetail: "api/Customers",
  getCustomerType: "api/CustomerType",
  searchCustomerType: "api/CustomerType/Search",

  //dashboard
  customerDashboard: "api/dashboard/customer",
  adminDashboard: "api/Dashboard/Administrator",

  // Roles Management
  roleManagement: "api/RoleManagement/Roles",

  // Staff Management
  staffManagement: "api/Staff",
  staffSupportLevelCount: "api/Staff/SupportLevel/Count",

  // Knowledge Base Module

  kbCategory: "api/MacroCategory",
  knowledgeBase: "api/Macro",
  keywords: "api/Keyword/unpaginated",

  //General
  exportAs: "api/General/ModuleFile",

  //Tickets
  getAllTicketCategoryUnpaginated: "api/tickets/TicketCategory/Unpaginated",
  getCustomerExistingTickets: "api/Tickets/Customer",
  createTicketResponseChat: "api/Tickets/TicketResponseChat",
  getTicketResponseChat: "api/Tickets/TicketResponseChat",
  routeTicket: "api/Tickets/Route",
  getAllTicketFeedbacks: "api/Tickets/Feedback",
  getTicketStatusCountForAnalytics: "api/Analytics/TicketCount",
  getTicketByStatusIdAnalytics: "api/Analytics/Tickets",
  getSplittedTickets: "api/Tickets/SplittedTicket",
  getMergedTickets: "api/Tickets/MergedTicket",
  getMergedTicketParent: "api/Tickets/Merge",
  getSplittedTicketParent: "api/Tickets/Split",
  getOneMergedTicket: "api/Tickets/MergedTicket",
  getOneSplittedTicket: "api/Tickets/SplittedTicket",
  getCustomerTicketsByStaff: "api/Tickets/Staff/Customer",
  ticketComment: "api/Tickets/TicketComment",
  ticketAutoRoute: "api/Tickets/AutoRoute",
  getTicketRoutePathsUnpaginated: "api/TicketRoutePaths/Unpaginated",
  changeTicketRouteType: "api/Tickets/RouteType/Change",

  // Tickets Categories
  ticketCategoriesUnpaginated: "api/Tickets/TicketCategory/Unpaginated",
  ticketCategoryById: "/api/Tickets/TicketCategory",
  ticketByCustomerId: "api/Tickets/Customer",
  getTicketByStatusId: "api/Tickets/TicketStatus",
  getTicketCount: "api/Tickets/CountByTicketStatus",
  getAllTicketsCategoryList: "api/Tickets/TicketCategory",
  searchTicketCategory: "api/Tickets/Category/Search",

  // Machine Learning
  getMlResponse: "api/AyudaML/SuggestResponse",

  //
  getMlResponseCustomer: "api/AyudaML/SuggestResponseCustomer",

  //Notifications
  getAllNotifications: "api/Notifications",
  getOneNotification: "api/Notifications",
  deleteNotification: "api/Notifications/Delete",
  clearSpecificUnread: "api/Notifications/Clear/SpecificUnread",
  clearAllUnread: "api/Notifications/Clear/AllUnread",

  //social integration
  whatsappManagement: "api/SocialMedia/WhatsApp",
  messengerManagement: "api/SocialMedia/Messenger",
  mailManagement: "api/MailManagement",

  //audit management
  auditManagement: "api/AuditReports",
  auditActivityManagement: "api/AuditReportActivities",
};
