import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Subject } from "rxjs";
import { Notification } from "../models/notification";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  connectionEstablished = new Subject<Boolean>();
  notification = new Subject<any>();
  public checNotification = this.notification.asObservable();

  constructor() {}

  startConnection() {
    if (!this.hubConnection) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.API_URL}/signalR/init`, {
          accessTokenFactory: () => localStorage.getItem("token"),
        })
        .withAutomaticReconnect()
        .build();
      this.hubConnection
        .start()
        .then(() => {
          console.log("Connection started");
          this.connectionEstablished.next(true);
        })
        .catch((err) => console.log("Error while starting connection: " + err));
      this.hubConnection.on("ReceiveNotification", (data) => {
        console.log("Recieved", data);
        this.notification.next(data);
      });
    }
  }
  disconnect() {
    if (this.hubConnection) {
      this.hubConnection.stop();
      this.hubConnection = null;
    }
  }
}
