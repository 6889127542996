import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ConfirmationPageComponent } from './auth/confirmation-page/confirmation-page.component';
import { SignupComponent } from './auth/signup/signup.component';
import { EmailConfirmationComponent } from './auth/email-confirmation/email-confirmation.component';

import { SharedComponentsModule } from './containers/shared.module';

import { JwtInterceptor } from './utils/jwt-interceptor';
import { JQ_TOKEN } from './_services/jQuery.service';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { AuthGuard } from './guards/auth.guard';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { StaffProfileCompletionComponent } from './auth/staff-profile-completion/staff-profile-completion.component';


const jQuery = window['$'];

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ConfirmationPageComponent,
    SignupComponent,
    EmailConfirmationComponent,
    VerifyEmailComponent,
    StaffProfileCompletionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // NgxChartsModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: ['localhost:4200'],
        blacklistedRoutes: ['http://localhost:4200/login']
      }
    }),
    NgApexchartsModule,
    BsDatepickerModule.forRoot(),
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JQ_TOKEN, useValue: jQuery },
    AuthGuard
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
