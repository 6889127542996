import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { GlobalService } from "src/app/_services/global.service";
import { TicketService } from "src/app/_services/ticket-catgry/ticket.service";

@Component({
  selector: "app-customer-last-three-tickets",
  templateUrl: "./customer-last-three-tickets.component.html",
  styleUrls: ["./customer-last-three-tickets.component.scss"],
})
export class CustomerLastThreeTicketsComponent implements OnInit {
  lists = [1, 2, 3];
  tickets: [];
  sub: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private ticketService: TicketService,
    private global: GlobalService
  ) {}

  ngOnInit(): void {
    this.getTickets();
  }

  getTickets() {
    const ticketId = this.route.snapshot.paramMap.get("id");
    this.global.showSpinner();
    this.sub.add(
      this.ticketService.getCustomerTopTicket(ticketId).subscribe({
        next: (response) => {
          this.tickets = response.objectValue;
          this.global.hideSpinnerWithSuccess(response.statusMessage);
        },
        error: (e) => {
          console.log(e);
          this.global.hideSpinnerWithError();
          this.global.handleGeneralErrors(e);
        },
      })
    );
  }
}
